
import { defineComponent, onBeforeMount, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { MenuItem } from 'primevue/menuitem'
import { useStaffFollowUpMenuItems } from './tab-menu-items'
import ModuleHeader from '@/components/commons/boxes/ModuleHeader.vue'
import LoadingScreen from '@/components/commons/LoadingScreen.vue'
import { CommonRouteNames } from '@/router/route-names'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { CoursePlanAPI } from '@/api/academic-definitions/course-plan.api'

export default defineComponent({
  components: {
    ModuleHeader,
    LoadingScreen
  },
  setup () {
    const menuItems = ref<MenuItem[]>([])
    const isLoading = ref(false)
    const router = useRouter()
    const errorRef = ref<null | unknown>(null)
    const allCoursePlans = ref<CoursePlan[]>([])
    onBeforeMount(() => {
      isLoading.value = true
    })

    onMounted(async () => {
      try {
        menuItems.value = useStaffFollowUpMenuItems()
        allCoursePlans.value = await CoursePlanAPI.findAllFollowing({ isFollowing: true })
      } catch (error) {
        errorRef.value = error
        router.push({
          name: CommonRouteNames.ERROR_SCREEN,
          params: {
            error: JSON.stringify(error)
          }
        })
      } finally {
        isLoading.value = false
      }
    })
    return {
      isLoading,
      errorRef,
      menuItems,
      allCoursePlans
    }
  }
})
