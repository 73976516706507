import { MenuItem } from 'primevue/menuitem'
import { EducationRouteNames } from '@/router/route-names'

export function useStaffFollowUpMenuItems (): MenuItem[] {
  return [
    {
      label: 'Profesores',
      to: {
        replace: true,
        name: EducationRouteNames.STAFF_FOLLOWUP_TEACHERS_ONBOARDING
      }
    },
    {
      label: 'Tutores',
      to: {
        replace: true,
        name: EducationRouteNames.STAFF_FOLLOWUP_TUTORS_REGULAR
      }
    },
    {
      label: 'Coordinadores',
      to: {
        replace: true,
        name: EducationRouteNames.STAFF_FOLLOWUP_COORDINATORS
      }
    }
  ]
}

export function useTeacherFollowUpMenuItems (): MenuItem[] {
  return [
    {
      label: 'Onboarding',
      to: {
        replace: true,
        name: EducationRouteNames.STAFF_FOLLOWUP_TEACHERS_ONBOARDING
      }
    },
    {
      label: 'Seguimiento',
      to: {
        replace: true,
        name: EducationRouteNames.STAFF_FOLLOWUP_TEACHERS_TRACING
      }
    }
  ]
}

export function useTutorsrFollowUpMenuItems (): MenuItem[] {
  return [
    {
      label: 'Regular',
      to: {
        replace: true,
        name: EducationRouteNames.STAFF_FOLLOWUP_TUTORS_REGULAR
      }
    }/* ,
    {
      label: 'Refuerzo',
      to: {
        replace: true,
        name: EducationRouteNames.STAFF_FOLLOWUP_TUTORS_CUSTOM
      }
    } */
  ]
}
